import React from "react";
import { Box } from "@mui/material";

interface RoundedImageProps {
    src: string;
    alt: string;
}

const RoundedImage: React.FC<RoundedImageProps> = ({ src, alt }) => {
    return (
        <Box
            component="img"
            sx={{
                width: "100%", // Ensure the image takes up 100% of the available width
                height: "auto", // Maintain the aspect ratio
                borderRadius: "16px", // Adjust the value to change the roundness
                maxWidth: "100%", // Ensure the image does not exceed the container width
                display: "block", // Ensure the image is displayed as a block element
                margin: "auto", // Center the image horizontally
            }}
            src={src}
            alt={alt}
        />
    );
};

export default RoundedImage;
