import { offering } from '@anything-pet/grpc-lib'
import { Socket } from 'socket.io-client';
import { emitEvent } from '../components/SocketIO';


/**
 * 
 * @param socket 
 * @returns 
 */
export function getOfferingServiceProxy(socket : Socket) : offering.OfferingService {
    return new BffOfferingServiceProxy(socket);
}

/**
 * 
 */
class BffOfferingServiceProxy implements offering.OfferingService {
    /**
     * 
     * @param socket 
     */
    constructor(private socket : Socket) { }

    /***
     * 
     */
    getOfferings(request: offering.GetOfferingsRequest): Promise<offering.GetOfferingsResponse> {
        return emitEvent<offering.GetOfferingsResponse, offering.GetOfferingsRequest>(
            this.socket, "offering:getOfferings", request
            );    
    }

    /**
     * 
     * @param request 
     * @returns 
     */
    getOffering(request: offering.GetOfferingRequest): Promise<offering.GetOfferingResponse> {
        return emitEvent<offering.GetOfferingResponse, offering.GetOfferingRequest>(
            this.socket, "offering:getOffering", request
            );    
    }
}