import { PropsWithChildren, useEffect, useState, useContext, createContext } from "react";
import { Navigate } from "react-router-dom";
import { getEnv } from "@anything-pet/common-util";
import { getVendorService } from "../services/VendorServiceProxy";
import { LinearProgress } from "@mui/material";
import { vendor } from "@anything-pet/grpc-lib";
import { useSocketIO } from "./SocketIO";

const hostNameSuffix = getEnv("REACT_APP_HOSTNAME_SUFFIX");
const vendorScopeContext = createContext<vendor.Vendor | undefined>(undefined);

/**
 * 
 * @returns 
 */
export function useVendorScope() {
    const vendor = useContext(vendorScopeContext);

    if (! vendor) {
        throw new Error('No vendor in scope');
    }

    return vendor;
}

/**
 * 
 * @param props 
 */
export function VendorScopeProvider(props: PropsWithChildren) {
    const socket = useSocketIO();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [vendorProfile, setVendorProfile] = useState<vendor.Vendor>();

    const hostName = window.location.hostname;

    if (! hostName.endsWith(hostNameSuffix)) {
        throw new Error(`Host name must be ${hostNameSuffix}`)
    }

    const [vendorCode] = hostName.split('.');

    useEffect(() => {
        (async () => {
            if (vendorCode) {
                const service = getVendorService(socket);

                const {vendor: resultVendor } = await service.getVendorByCode({ code: vendorCode });

                if (resultVendor) {
                    setVendorProfile(resultVendor);

                    document.title = resultVendor.name;
                }
            }

            setIsLoading(false);
        })();
    }, [vendorCode]);

    if (isLoading) {
        return (
            <LinearProgress />
        )
    }

    if (! vendorProfile) {
        return <Navigate to='/not-found' />;
    }

    return (
        <vendorScopeContext.Provider value={vendorProfile}>
            {props.children}
        </vendorScopeContext.Provider>
    )
}