import { useEffect, useState } from "react";
import { useVendorScope } from "./VendorScopeProvider";
import { getOfferingServiceProxy } from "../services/OfferingServiceProxy";
import { useSocketIO } from "./SocketIO";
import { offering } from "@anything-pet/grpc-lib";
import {
    Box,
    Button,
    Divider,
    Skeleton,
    List,
    ListItem,
    ListItemText,
    Grid,
    Typography,
} from "@mui/material";
import SectionHeading from "./SectionHeading";
import { useNavigate } from "react-router-dom";
import { getEnv } from "@anything-pet/common-util";


const consumerSiteUrl: string = getEnv("REACT_APP_CONSUMER_SITE_URL");

export function Offerings() {
    const navigate = useNavigate();

    const socket = useSocketIO();
    const vendor = useVendorScope();
    const [offerings, setOfferings] = useState<offering.Offering[]>();
    const [bookingDialogOpen, setBookingDialogOpen] = useState(false);

    useEffect(() => {
        (async () => {
            const service = getOfferingServiceProxy(socket);
            const result = await service.getOfferings({ vendorId: vendor.id });
            setOfferings(result.offering);
        })();
    }, [socket, vendor.id]);

    if (!offerings) {
        return (
            <>
                <SectionHeading>Services</SectionHeading>
                <Skeleton variant="rectangular" height={60} />
            </>
        );
    }

    const listStyle = {
        py: 0,
        width: "100%",
        borderRadius: 2,
        border: "1px solid",
        borderColor: "divider",
        backgroundColor: "background.paper",
    };

    return (
        <>
            <SectionHeading>Services</SectionHeading>
            <List sx={listStyle}>
                {offerings.map((offering, index) => (
                    <Box key={offering.id}>
                        <ListItem component="div">
                            <Grid container spacing={1} alignItems="center">
                                <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                    sx={{
                                        textAlign: {
                                            xs: "center",
                                            sm: "left",
                                        },
                                    }}
                                >
                                    <ListItemText
                                        id={`offering-list-item-${offering.id}`}
                                        primary={
                                            <Typography fontWeight="bold">
                                                {offering.name}
                                            </Typography>
                                        }
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                    sx={{ textAlign: "center" }}
                                >
                                    <ListItemText
                                        id={`offering-list-item-${offering.id}`}
                                        primary={`${offering.duration} mins`}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                    sx={{ textAlign: "center" }}
                                >
                                    <ListItemText
                                        id={`offering-list-item-${offering.id}`}
                                        primary={`$${offering.price.toLocaleString(
                                            undefined,
                                            { minimumFractionDigits: 2 }
                                        )}`}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                    container
                                    justifyContent={{
                                        xs: "center",
                                        sm: "flex-end",
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        href={`${consumerSiteUrl}/book/${vendor.code}/${offering.id}`}
                                        sx={{ marginTop: { xs: 2, sm: 0 } }}
                                    >
                                        Book
                                    </Button>
                                </Grid>
                            </Grid>
                        </ListItem>
                        {index < offerings.length - 1 && (
                            <Divider component="li" />
                        )}
                    </Box>
                ))}
            </List>
        </>
    );
}
