import React from "react";
import { Box } from "@mui/material";

interface GoogleMapIFrameProps {
    src: string;
}

const GoogleMapIFrame: React.FC<GoogleMapIFrameProps> = ({src}) => {
    return (
        <Box paddingTop="20px">
                <iframe
                    src={src}
                    width="100%"
                    height="450"
                    loading="lazy"
                    style={{ border: "0", borderRadius: "16px" }}
                ></iframe>
            </Box>
    );
};

export default GoogleMapIFrame;