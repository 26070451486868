import React, { useEffect, useState } from "react";
import {
    Grid,
    Avatar,
    Typography,
    Box,
    LinearProgress,
    Skeleton,
} from "@mui/material";
import { useSocketIO } from "./SocketIO";
import { getStaffServiceProxy } from "../services/StaffServiceProxy";
import SectionHeading from "../components/SectionHeading";

interface RoundedImageProps {
    src: string;
    alt: string;
    width: string;
}

interface TeamMember {
    name: string;
    position: string;
}

const TeamMembers: React.FC<{ vendorId: string }> = (props) => {
    const socket = useSocketIO();
    const [teamMembers, setTeamMembers] = useState<TeamMember[]>();

    useEffect(() => {
        (async () => {
            const service = getStaffServiceProxy(socket);

            const response = await service.getStaffs({
                vendorId: props.vendorId,
            });

            setTeamMembers(
                response.staff.map((staff) => {
                    return {
                        name: `${staff.firstName} ${staff.lastName}`,
                        position: staff.position,
                    };
                })
            );
        })();
    }, [props.vendorId]);

    if (!teamMembers) {
        return (
            <>
                <SectionHeading>Team</SectionHeading>
                <Skeleton variant="rectangular" height={60} />
            </>
        );
    }

    return (
        <div>
            <SectionHeading>Team</SectionHeading>
            <Grid container spacing={3}>
                {teamMembers.map((member, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                        >
                            <Avatar sx={{ width: 56, height: 56 }}>
                                {member.name.charAt(0).toUpperCase()}
                            </Avatar>
                            <Typography variant="h6">{member.name}</Typography>
                            <Typography variant="body2">
                                {member.position}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default TeamMembers;
