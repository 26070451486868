import React from "react";
import { Typography } from "@mui/material";
import SectionHeading from "./SectionHeading";

interface VendorAdditionalInfoProps {
    additionalInfo: string;
}

const VendorAdditionalInfo: React.FC<VendorAdditionalInfoProps> = ({
    additionalInfo,
}) => {
    return (
        <>
            <SectionHeading>Additional Info</SectionHeading>
            <Typography variant="body1">
                <span dangerouslySetInnerHTML={{ __html: additionalInfo }} />
            </Typography>
        </>
    );
};

export default VendorAdditionalInfo;
