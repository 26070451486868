import React from "react";
import {
    AppBar,
    Box,
    Toolbar,
    Container,
} from "@mui/material";
import { getEnv } from "@anything-pet/common-util";
import logo from "../resources/Logo160x60.png";

const NavBar: React.FC = () => {
    const consumerSiteUrl: string = getEnv("REACT_APP_CONSUMER_SITE_URL");
    
    return (
        <AppBar position="static" color="default">
            <Container>
                <Toolbar>
                    <Box style={{ flexGrow: 1 }} padding={"10px"}>
                        <img src={logo} />
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default NavBar;
