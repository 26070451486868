import { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import { io, Socket } from 'socket.io-client';
import { getEnv } from '@anything-pet/common-util'
import { LinearProgress } from "@mui/material";


const socketApiUrl = getEnv("REACT_APP_SOCKETAPI_URL");

const context = createContext<Socket | null>(null);

/**
 * Create Socket.io socket
 * @param url 
 * @param accessToken 
 * @returns 
 */
function createSocket(url: string, accessToken?: string): Promise<Socket> {
    
    return new Promise((resolve, reject) => {
        const socket = io(url, {
            auth: accessToken ? {
                token: accessToken
            } : undefined,
            transports: ['websocket', 'polling']
        });
        
        socket.on("disconnect", () => {
            console.log(`Disconnected: ${socket.id}`);                     
        });

        socket.on("connect", () => {
            console.log(`Connected: ${socket.id}`);
        })
    
        let connected = false;

        socket.once("connect", () => {            
            connected = true;
            resolve(socket);            
        });

        socket.once("connect_error", (err) => {
            if (!connected) {
                socket.disconnect();

                reject(err);
            }
        });
    
        socket.connect();
    });

}

/**
 * 
 * @returns 
 */
export function useSocketIO() : Socket {
    const io = useContext(context);

    if (!io) {
        throw new Error("SocketIO is not read");
    }

    return io;
}

/**
 * 
 * @param obj 
 * @returns 
 */
function isError(obj : any) : obj is Error {
    return obj.code && obj.details;
}

/**
 * Helper function to send a request/response request to server as a promise
 * @param socket 
 * @param eventName 
 * @param args 
 */
export function emitEvent<TResult = any, TRequest = any>(socket: Socket, eventName: string, request: TRequest): Promise<TResult> {
    return new Promise<TResult>((resolve, reject) => {
        socket.emit(eventName, request, (result: TResult) => {
            if (isError(result)) {
                reject(result);
            } else {
                resolve(result);
            }
        })    
    });
}

/**
 * Helper function to subscribe an event
 * @param socket 
 * @param subscriptionId 
 * @param eventName 
 * @param callback 
 */
export function subscribeEvent<TMessage = any>(
    socket: Socket, subscriptionId: string, eventName: string, 
    callback: (message: TMessage) => void
) {
    socket.emit('subscribeEvent', subscriptionId, eventName);

    //  listen to event
    socket.on(eventName, callback);
}


export function SocketIOProvider(props: PropsWithChildren) {
    const [socket, setSocket] = useState<Socket | null>(null);
    const [error, setError] = useState<Error | null>(null);
    
    useEffect(() => {
        (async function() {
            const result = await createSocket(socketApiUrl);
        
            setSocket(result);
        })();

        return () => {
            if (socket) {
                console.log(`Closing connection so ${socket.id}`);
                socket.close();
            }
        };
    }, []);

    if (error) {
        return (
            <div>
                <p>{ `Error: ${error.message}` }</p>
            </div>
            );
    }

    if (!socket) {
        return <LinearProgress />
    }

    return (
        <context.Provider value={socket}>
            {props.children}
        </context.Provider>
    )
}
