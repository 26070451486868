import "./App.css";
import { Route, Routes } from "react-router-dom";
import { NotFound } from "./pages/NotFound";
import { VendorHome } from "./pages/VendorHome";
import NavBar from "./components/NavBar";
import { ThemeProvider } from "@mui/material/styles";
import { Box, Divider, Container, CssBaseline } from "@mui/material";
import Theme from "./components/Theme";
import SiteFooter from "./components/SiteFooter";

const link = document.createElement("link");
link.href =
    "https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap";
link.rel = "stylesheet";
document.head.appendChild(link);

function App() {
    return (
        <ThemeProvider theme={Theme}>
            <CssBaseline />
            <NavBar />
            <Container>
                <Routes>
                    <Route path="/not-found" element={<NotFound />} />
                    <Route path="*" element={<VendorHome />} />
                </Routes>
            </Container>
            <Box paddingTop={"40px"} paddingBottom={"40px"}>
                <Divider></Divider>
            </Box>
            <Container>
                <SiteFooter />
            </Container>
        </ThemeProvider>
    );
}

export default App;
