import { staff } from '@anything-pet/grpc-lib'
import { Socket } from 'socket.io-client';
import { emitEvent } from '../components/SocketIO';


/**
 * 
 * @param socket 
 * @returns 
 */
export function getStaffServiceProxy(socket : Socket) : staff.StaffService {
    return new BffStaffServiceProxy(socket);
}

/**
 * 
 */
class BffStaffServiceProxy implements staff.StaffService {
    /**
     * 
     * @param socket 
     */
    constructor(private socket : Socket) { }

    /**
     * 
     * @param request 
     * @returns 
     */
    getVendorAccess(request: staff.GetVendorAccessRequest): Promise<staff.GetVendorAccessResponse> {
        return emitEvent<staff.GetVendorAccessResponse, staff.GetVendorAccessRequest>(
            this.socket, "staff:getVendorAccess", request
        );
    }

    /**
     * 
     * @param request 
     * @returns 
     */
    getStaffs(request: staff.GetStaffsRequest): Promise<staff.GetStaffsResponse> {
        return emitEvent<staff.GetStaffsResponse, staff.GetStaffsRequest>(
            this.socket, "staff:getStaffs", request
        );
    }
    
    /**
     * 
     * @param request 
     * @returns 
     */
    getStaffsByOffering(request: staff.GetStaffsByOfferingRequest): Promise<staff.GetStaffsByOfferingResponse> {
        return emitEvent<staff.GetStaffsByOfferingResponse, staff.GetStaffsByOfferingRequest>(
            this.socket, "staff:getStaffsByOffering", request
        );
    }

    /**
     * 
     * @param request 
     * @returns 
     */
    getStaff(request: staff.GetStaffRequest): Promise<staff.GetStaffResponse> {
        return emitEvent<staff.GetStaffResponse, staff.GetStaffRequest>(
            this.socket, "staff:getStaff", request
        );
    }

    /**
     * 
     * @param request 
     * @returns 
     */
    getSupportedOfferings(request: staff.GetSupportedOfferingsRequest): Promise<staff.GetSupportedOfferingsResponse> {
        return emitEvent<staff.GetSupportedOfferingsResponse, staff.GetSupportedOfferingsRequest>(
            this.socket, "staff:getSupportedOfferings", request
        );
    }
}