import { Navigate, Route, Routes } from "react-router-dom";
import { AnonymousHome } from "./AnonymousHome";
import { VendorScopeProvider } from "../components/VendorScopeProvider";
import { Offerings } from "../components/Offerings";
import { SocketIOProvider } from "../components/SocketIO";
import { Box } from "@mui/material";


export function VendorHome() {
    return (
        <SocketIOProvider>
            <VendorScopeProvider>
                <Box sx={{ padding: 3 }}>
                    {/* <LoginStatus /> */}
                    <Routes>
                        <Route index element={ <AnonymousHome /> } />
                        <Route path="services" element={<Offerings />} />
                        <Route path="*" element={<Navigate to='/not-found' />} />
                    </Routes>
                </Box>
            </VendorScopeProvider>
        </SocketIOProvider>
    );
}