import { useVendorScope } from "../components/VendorScopeProvider";
import { Navigate } from "react-router";
import { Link, Skeleton, Typography } from "@mui/material";
import RoundedImage from "../components/RoundedImage";
import TeamMembers from "../components/TeamMembers";
import VendorAbout from "../components/VendorAbout";
import VendorAdditionalInfo from "../components/VendorAdditionalInfo";
import vendorMainImage from "../resources/vendor-default-main-image.png";
import GoogleMapIFrame from "../components/GoogleMapIFrame";
import VendorBusinessHours from "../components/VendorBusinessHours";
import { BusinessHour } from "../components/BusinessHours";

import { getOfficeHoursAsArray, vendor } from "@anything-pet/grpc-lib";
import { Offerings } from "../components/Offerings";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

/**
 *
 * @param hours
 */
function toBusinessHours(
    day: string,
    hours?: vendor.OfficeDayHours
): BusinessHour {
    const outputFormat = "h:mm A";

    const open = hours?.startTime
        ? dayjs(hours.startTime, "HH:mm").format(outputFormat)
        : "Closed";
    const close = hours?.endTime
        ? dayjs(hours.endTime, "HH:mm").format(outputFormat)
        : "Closed";

    return {
        day: day,
        open: open,
        close: close,
    };
}

/**
 *
 * @param hours
 */
function getOpenUtilText(hours?: vendor.OfficeHours): string {
    const now = dayjs();

    const hourArray = getOfficeHoursAsArray(hours);

    const todayHours = hourArray[now.day()];

    if (!todayHours) {
        return "Closed";
    } else {
        const startTime = dayjs(todayHours.startTime, "HH:mm");
        const endTime = dayjs(todayHours.endTime, "HH:mm");

        if (now.isBefore(startTime)) {
            return `Open at ${startTime.format("h:mm A")}`;
        } else if (now.isBefore(endTime)) {
            return `Open until ${endTime.format("h:mm A")}`;
        } else {
            return "Closed";
        }
    }
}

/**
 *
 * @returns
 */
export function AnonymousHome() {
    const vendor = useVendorScope();

    if (!vendor) {
        //  Loading profile
        return (
            <>
                <Skeleton variant="rectangular" height={60} />
            </>
        );
    } else {
        document.title = vendor.name + " - " + vendor.address + " | Powered by Anything Pet" ;
    }

    const city = vendor.location;
    const mainImage = (vendor.images ?? []).find(i => i.name === 'main')?.url ?? vendorMainImage;
    const vendorAddress = vendor.address;
    const vendorAboutText = vendor.about;
    const vendorAdditionalInfoText = vendor.additionalInfo || "";
    const businessHours: BusinessHour[] = [
        toBusinessHours("Monday", vendor.officeHours?.monday),
        toBusinessHours("Tuesday", vendor.officeHours?.tuesday),
        toBusinessHours("Wednesday", vendor.officeHours?.wednesday),
        toBusinessHours("Thursday", vendor.officeHours?.thursday),
        toBusinessHours("Friday", vendor.officeHours?.friday),
        toBusinessHours("Saturday", vendor.officeHours?.saturday),
        toBusinessHours("Sunday", vendor.officeHours?.sunday),
    ];

    return (
        <>
            <Typography variant="h2" gutterBottom>
                {vendor.name}
            </Typography>
            <Typography variant="body2" paddingBottom="10px">
                {getOpenUtilText(vendor.officeHours)} &bull; {city}
            </Typography>
            <RoundedImage src={mainImage} alt="Main Image" />

            <Offerings />

            <TeamMembers vendorId={vendor.id} />

            <VendorAbout about={vendorAboutText} />

            <GoogleMapIFrame src={vendor.googleMapSrc} />

            <Typography variant="body1">
                Address: {vendorAddress}
            </Typography>

            <VendorBusinessHours hours={businessHours} />

            <VendorAdditionalInfo additionalInfo={vendorAdditionalInfoText} />
        </>
    );
}
