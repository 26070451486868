import React, { PropsWithChildren } from "react";
import { Typography } from "@mui/material";

const SectionHeading: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <Typography variant="h4" gutterBottom paddingTop="20px">
            {children}
        </Typography>
    );
};

export default SectionHeading;
