import React from "react";
import { Typography } from "@mui/material";
import SectionHeading from "../components/SectionHeading";

interface VendorAboutProps {
    about: string;
}

const VendorAbout: React.FC<VendorAboutProps> = ({ about }) => {
    return (
        <div>
            <SectionHeading>About</SectionHeading>
            <Typography variant="body1">
                <span dangerouslySetInnerHTML={{ __html: about }} />
            </Typography>
        </div>
    );
};

export default VendorAbout;
