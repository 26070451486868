import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Box,
} from "@mui/material";
import { BusinessHour } from "./BusinessHours"; // Adjust the path as necessary
import SectionHeading from "./SectionHeading";

interface BusinessHoursProps {
    hours: BusinessHour[];
}

const BusinessHours: React.FC<BusinessHoursProps> = ({ hours }) => {
    return (
        <Box>
            <SectionHeading>Business Hours</SectionHeading>
            <TableContainer component={Paper}>
                <Table  sx={{ border: 'none' }}>
                    <TableBody>
                        {hours.map((hour, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row" sx={{ borderBottom: 'none' }}>
                                    {hour.day}
                                </TableCell>
                                <TableCell sx={{ borderBottom: 'none' }}>
                                    {hour.open === "Closed"
                                        ? "Closed"
                                        : `${hour.open} - ${hour.close}`}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default BusinessHours;
